import { combineReducers } from 'redux'
import pagesReducer from './pagesReducer'
import browserLanguagesReducer from './browserLanguagesReducer'
import uiReducer from './uiReducer'
import optionsReducer from './optionsReducer'

const rootReducer = combineReducers({
	pages: pagesReducer,
	browserLanugages: browserLanguagesReducer,
	ui: uiReducer,
	options: optionsReducer
})

export default rootReducer

import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import getElementOffset from '../utils/getElementOffset'

const TryToLogPage = () => {
	// ReactGA.set({ userId })
	if (document.title !== 'loading...') {
		ReactGA.pageview(window.location.pathname)
		// console.log(
		// 	'Page Load: ' + window.location.pathname + '\n' + document.title
		// )
	} else {
		console.log('waiting...')
		window.setTimeout(TryToLogPage, 500)
	}
}

const scrollToHash = originalHash => {
	// console.log(`${hash}_anchor`)
	// console.log($(`${hash}_anchor`))
	const hash = originalHash.split('#')[1] // remove the #
	var target = document.getElementById(hash)
	// console.log('_________SCROLL TO: _________')
	let offset
	if (target) {
		offset = getElementOffset(target).top
		console.log(offset)
		window.setTimeout(() => {
			window.scrollTo({
				top: offset - 140,
				left: 0,
				behavior: 'smooth'
			})
		}, 250)
	}
}

class PageLoad extends Component {
	componentDidMount() {
		// window.setTimeout(TryToLogPage, 500)
	}

	componentDidUpdate(prevProps) {
		// if (this.props.location.pathname !== prevProps.location.pathname) {
		// console.log(this.props.location.pathname.split('/~')[0])
		const { hash } = this.props.location
		if (
			this.props.location.pathname.split('/~')[0] !==
			prevProps.location.pathname.split('/~')[0]
		) {
			window.scrollTo(0, 0)
			window.setTimeout(() => TryToLogPage(this.props.login), 500)
		}

		// if (hash !== prevProps.location.hash) {
		// if (hash !== '#newsletter') {
		scrollToHash(hash)
		// }
		// }
	}

	render() {
		return this.props.children
	}
}

const mapStateToProps = state => ({})

export default withRouter(connect(mapStateToProps)(PageLoad))

import React from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
// import _t from '../utils/translate'
import Settings from '../Settings'

// import he from 'he'

// <div className="footer_wrapper">
// 	<div className="footer">
// 		<span className="name">
// 			Praxis für Psychotherapie
// 			<br />
// 			Zeller-König
// 		</span>
// 		<div className="footer_menu">
// 			{Settings.footerMenu.map(entry => (
// 				<div
// 					className="block"
// 					key={`menu_link_${entry.url}`}
// 				>
// 					<Link className="main" to={entry.url}>
// 						{he.decode(entry.title)}
// 					</Link>
// 					{entry.subpages.map(subentry => (
// 						<Link
// 							key={`footer_subpages_link${subentry.url}`}
// 							to={subentry.url}
// 						>
// 							{he.decode(subentry.title)}
// 						</Link>
// 					))}
// 				</div>
// 			))}
// 		</div>
// 	</div>
// </div>

const Footer = ({ pages, lang, options }) => {
  return (
    <>
      <div className="footer_wrapper">
        <div className="footer mobile">
          <span className="name">
            Praxis für Psychotherapie
            <br />
            Zeller-König
          </span>
          <div className="link_holder">
            {Settings.mobileMenu.map((menuItem) => (
              <NavLink key={`mobile_menu_${menuItem.url}`} to={menuItem.url}>
                {menuItem.title}
              </NavLink>
            ))}
          </div>
          <Link
            className="imprint"
            to={!options.loading ? options.data.de.footer.impressum_link : ''}
          >
            {!options.loading && options.data.de.footer.impressum_text}
          </Link>
        </div>
        <div className="footer desktop">
          <span className="name">
            Praxis für Psychotherapie
            <br />
            Zeller-König
          </span>
          <div className="flex">
            <div className="info_left">
              <div className="footer_text">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      (!options.loading && options.data.de.footer.info_left) ||
                      null,
                  }}
                />
                <Link
                  to={
                    !options.loading
                      ? options.data.de.footer.impressum_link
                      : ''
                  }
                >
                  {!options.loading && options.data.de.footer.impressum_text}
                </Link>
              </div>
            </div>
            {!options.loading &&
              options.data.de.footer &&
              options.data.de.footer.links.length > 0 &&
              options.data.de.footer.links.map((page) => (
                <div
                  className="link_block"
                  key={`footer_menu_${page.page_link}`}
                >
                  <NavLink className="main" to={page.page_link}>
                    {page.page}
                  </NavLink>
                  {page.sublinks.length &&
                    page.sublinks.map((sublink, i) => (
                      <NavLink
                        key={`footer_submenu_${sublink.link}_${i}`}
                        to={sublink.link}
                      >
                        {sublink.text}
                      </NavLink>
                    ))}
                </div>
              ))}
          </div>
        </div>
        <div className="copyright">
          © {new Date().getFullYear()} Praxis für Psychotherapie Zeller-König
        </div>
        <div className="by">
          Webdesign &amp; -programmierung:{' '}
          <a href="https://one-squared.com">&Oslash;NEsquared</a>
        </div>

        {!options.loading && Settings.debugBlocks && (
          <pre>{JSON.stringify(options.data.de.footer, null, 2)}</pre>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  options: state.options,
})

export default connect(mapStateToProps)(Footer)

import React from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadLanguage } from '../actions/pagesActions'
import _t from '../utils/translate'
import Settings from '../Settings'

import { ReactComponent as Logo } from '../img/logo.svg'

class MainMenu extends React.Component {
	constructor(props) {
		super(props)
		this.accountButtonHandler = this.accountButtonHandler.bind(this)
		this.changeLanguage = this.changeLanguage.bind(this)
	}

	accountButtonHandler() {
		const { showLoginPopup, loggedIn, history } = this.props // eslint-disable-line
		loggedIn ? history.push('/account/') : showLoginPopup() // eslint-disable-line
	}

	changeLanguage() {
		this.props.loadLanguage('en')
	}

	render() {
		const { language, options } = this.props
		// const { changeLanguage } = this
		return (
			<div className="main_menu visible">
				<Link to="/">
					<Logo className="logo" />
				</Link>
				<div className="links">
					{!options.loading &&
						options.data.de.main_menu &&
						options.data.de.main_menu.menu_items.length > 0 &&
						options.data.de.main_menu.menu_items.map(page => (
							<div
								className="dropdown"
								key={`footer_menu_${page.page_link}`}
							>
								<NavLink className="main" to={page.page_link}>
									{page.page}
								</NavLink>
								<div className="dropdown_holder">
									{page.dropdown.length &&
										page.dropdown.map((sublink, i) => (
											<Link
												className="dropdown_item"
												key={`footer_submenu_${sublink.subpage}_${i}`}
												to={sublink.subpage_link}
											>
												{sublink.subpage}
											</Link>
										))}
								</div>
							</div>
						))}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	language: state.ui.language,
	options: state.options
})

export default withRouter(connect(mapStateToProps, { loadLanguage })(MainMenu))

import React from 'react'
import PropTypes from 'prop-types'

import Reveal from 'react-reveal/Reveal'
import shortid from 'shortid'

import Text from './blocks/Text'
import HeaderImage from './blocks/HeaderImage'
import PageLink from './blocks/PageLink'
import Slider from './blocks/Slider'
import Map from './blocks/Map'

// import Slider from './blocks/Slider'
// import Video from './blocks/Video'
// import ThreeColumns from './blocks/ThreeColumns'
// import Banner from './blocks/Banner'
// import HorizontalMenu from './blocks/HorizontalMenu'
// import EssentialsDisplay from './blocks/EssentialsDisplay'
// import TitleWithIcon from './blocks/TitleWithIcon'
// import TwoImages from './blocks/TwoImages'
// import Quote from './blocks/Quote'
// import TeamMember from './blocks/TeamMember'
// import LinkShop from './blocks/LinkShop'
// import HealthTopicsDisplay from './blocks/HealthTopicsDisplay'
// import HistoryDisplay from './blocks/HistoryDisplay'
// import RemedyDisplay from './blocks/RemedyDisplay'
// import ProductDisplay from './blocks/ProductDisplay'
// import LandingLinkShop from './blocks/LandingLinkShop'
// import SeasonalRemedyDisplay from './blocks/SeasonalRemedyDisplay'

const BlocksRenderer = ({ loading, page }) => {
  console.log(page)
  if (loading || !page?.acf?.blocks?.length > 0) return null

  return (
    <React.Fragment>
      {page.acf.blocks.map((element, n) => {
        // Display all layout names for debug:
        // return <p>{element.acf_fc_layout}</p>
        switch (element.acf_fc_layout) {
          case 'text':
            if (element.disable_fade_in)
              return <Text key={shortid.generate()} apiElement={element} />
            return (
              <Reveal effect="custom_fade_in" key={shortid.generate()}>
                <Text apiElement={element} />
              </Reveal>
            )

          case 'header_image':
            return (
              <Reveal effect="custom_fade_in" key={shortid.generate()}>
                <HeaderImage
                  imageUrl={element.image.sizes.large}
                  apiElement={element}
                />
              </Reveal>
            )

          case 'page_link':
            return (
              <Reveal effect="custom_fade_in" key={shortid.generate()}>
                <PageLink apiElement={element} />
              </Reveal>
            )

          case 'slider':
            return (
              <Reveal effect="custom_fade_in" key={shortid.generate()}>
                <Slider apiElement={element} />
              </Reveal>
            )

          case 'map':
            return (
              <Reveal effect="custom_fade_in" key={shortid.generate()}>
                <Map apiElement={element} />
              </Reveal>
            )

          default:
            return (
              <p
                style={{
                  color: 'red',
                  textAlign: 'center',
                }}
                key={shortid.generate()}
              >
                Block <b>'{element.acf_fc_layout}'</b> not found!
              </p>
            )
        }
      })}
      {(!loading &&
        'acf' in page &&
        'blocks' in page.acf &&
        page.acf.blocks.length > 0) || <h1>no content</h1>}
    </React.Fragment>
  )
}

BlocksRenderer.defaultProps = {
  page: {},
}

BlocksRenderer.propTypes = {
  loading: PropTypes.bool.isRequired,
  page: PropTypes.object, // eslint-disable-line
}

export default BlocksRenderer

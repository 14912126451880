import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { hideMessage } from '../actions/uiActions'

class Message extends React.Component {
	componentDidUpdate(prevProps) {
		if (this.props.messageVisible && !prevProps.messageVisible) {
			this.timer = setTimeout(() => {
				this.props.hideMessage()
			}, this.props.messageDuration)
			// this.classMethod();
		}
	}

	render() {
		const {
			messageVisible,
			messageType,
			messageText,
			hideMessage
		} = this.props
		return (
			<React.Fragment>
				<div
					className={`message_overlay ${
						messageVisible ? 'visible' : ''
					}`}
				/>
				<div
					className={`message ${
						messageVisible ? 'visible' : ''
					} ${messageType}`}
					onClick={hideMessage}
				>
					<p>{messageText}</p>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => ({
	messageVisible: state.ui.messageVisible,
	messageText: state.ui.messageText,
	messageType: state.ui.messageType,
	messageDuration: state.ui.messageDuration
})

Message.propTypes = {
	messageVisible: PropTypes.bool.isRequired,
	messageText: PropTypes.string,
	messageType: PropTypes.string,
	messageDuration: PropTypes.number
}

Message.defaultProps = {
	messageDuration: 3000,
	messageType: '',
	messageText: ''
}

export default connect(
	mapStateToProps,
	{ hideMessage }
)(Message)

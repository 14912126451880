import React from 'react'
import { NavLink, withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { showMenu, hideMenu } from '../actions/uiActions'
import Settings from '../Settings'
// import _t from '../utils/translate'

class MainMenu extends React.Component {
    constructor(props) {
        super(props)
        this.toggleMenu = this.toggleMenu.bind(this)
        // state={transition }
    }

    toggleMenu() {
        const { menuVisible, showMenu, hideMenu } = this.props

        if (!menuVisible) {
            showMenu()
        } else {
            hideMenu()
        }
    }

    render() {
        const { menuVisible, language, options } = this.props
        // const { changeLanguage } = this

        return (
            <React.Fragment>
                <NavLink className="mobile_logo" to={`/`} />
                <div className={`mobile_menu ${menuVisible ? 'open' : ''}`}>
                    <div
                        className={`mobile_menu_overlay ${
                            menuVisible && 'visible'
                        }`}
                    >
                        <div className="link_holder">
                            {Settings.mobileMenu.map(menuItem => (
                                <NavLink
                                    key={`mobile_menu_${menuItem.url}`}
                                    to={menuItem.url}
                                    onClick={this.toggleMenu}
                                >
                                    {menuItem.title}
                                </NavLink>
                            ))}
                        </div>
                        <Link
                            className="imprint"
                            to={
                                !options.loading
                                    ? options.data.de.footer.impressum_link
                                    : ''
                            }
                            onClick={this.toggleMenu}
                        >
                            {!options.loading &&
                                options.data.de.footer.impressum_text}
                        </Link>
                    </div>
                    <button
                        className="mobile_menu_button"
                        onClick={this.toggleMenu}
                    >
                        <div className="bar_holder">
                            <div className="bar" />
                            <div className="bar" />
                            <div className="bar" />
                        </div>
                    </button>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    language: state.ui.language,
    menuVisible: state.ui.menuVisible,
    options: state.options
})

export default withRouter(
    connect(mapStateToProps, { showMenu, hideMenu })(MainMenu)
)

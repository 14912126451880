import { store } from '../store/store'
import { OPTIONS_LOADED, SET_ERROR } from './types'
import { api } from '../api'
import Settings from '../Settings'

export const optionsLoaded = pages => ({ type: OPTIONS_LOADED, payload: pages })

function handleErrors(responses) {
    // console.log(response)

    responses.map(response => {
        if (response.status !== 200) {
            throw Error(JSON.stringify(response))
        } else return response
    })
    return responses
}

export const loadOptions = () => {
    console.log('LOAD OPTIONS')
    let promises = []
    Settings.supportedLanguages.map(lang => {
        promises.push(api.options.getOptionsLang(lang))
        return null
    })
    Promise.all(promises)
        .then(res => handleErrors(res))
        .then(res => {
            let allLanguagesOptions = {}
            res.map((singleRes, x) => {
                allLanguagesOptions[Settings.supportedLanguages[x]] =
                    singleRes.data.acf
                return null
            })
            // console.log(allLanguagesOptions)
            store.dispatch(optionsLoaded({ data: { ...allLanguagesOptions } }))
        })
        .catch(function (err) {
            // console.error(`Failed to load pages: ${err.message}`); // some coding error in handling happened
            store.dispatch({ type: SET_ERROR, payload: err.message })

            window.setTimeout(() => {
                loadOptions()
                // console.log('do it again');
            }, 3000)
        })
}

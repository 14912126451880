export const LOAD_PAGES = 'LOAD_PAGES'

export const LOAD_BROWSER_LANGUAGES = 'LOAD_BROWSER_LANGUAGES'

export const HIDE_POPUPS = 'HIDE_POPUPS'

export const SET_LOADING = 'SET_LOADING'

export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'

export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_ALL_POPUPS = 'HIDE_ALL_POPUPS'

export const SET_LANGUAGE = 'SET_LANGUAGE'
export const LANGUAGE_LOADED = 'LANGUAGE_LOADED'

export const HIDE_MESSAGE = 'HIDE_MESSAGE'

export const SET_ERROR = 'SET_ERROR '
export const OPTIONS_LOADED = 'OPTIONS_LOADED'

export const SHOW_MENU = 'SHOW_MENU'
export const HIDE_MENU = 'HIDE_MENU'

import React from 'react'
import PropTypes from 'prop-types'

// const regEx = `/\[icon\s?(?:name=")?([^\]]*)\"\]/g`

const Text = ({ apiElement }) => {
	const shortcodesApplied = apiElement.text.replace(
		/\[icon_([^\]]*)\]/g,
		(matchedString, name) => {
			// const iconName = name !== '' ?  name : 'homeopathy'

			return `<div class="icon shortcode icon_${name} active" ></div>`
		}
	)

	const withTitle = `<h2>${apiElement.title}</h2>${shortcodesApplied}`

	return (
		<div
			className={`text_block align_${apiElement.text_align}`} // eslint-disable-next-line
			id={apiElement.anchor_name}
			dangerouslySetInnerHTML={{
				__html: withTitle || null
			}}
		/>
	)
}

Text.defaultProps = {
	apiElement: {}
}
Text.propTypes = {
	apiElement: PropTypes.object // eslint-disable-line
}

export default Text

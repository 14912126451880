// import _ from 'lodash'

// import urlFormat from '../utils/urlFormat'

import { OPTIONS_LOADED } from '../actions/types'

const initialState = {
	data: {},
	loading: true
}

export default function (state = initialState, action) {
	switch (action.type) {
		case OPTIONS_LOADED:
			// console.log(action.payload)

			return {
				...action.payload,
				loading: false
			}
		default:
			return state
	}
}

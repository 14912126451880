import { LOAD_PAGES, SET_LANGUAGE, LANGUAGE_LOADED } from '../actions/types'

const initialState = {
	data: {},
	loading: true,
	language: 'en'
}

export default function(state = initialState, action) {
	switch (action.type) {
		case LOAD_PAGES:
			return { ...state, ...action.payload, loading: false }
		case SET_LANGUAGE:
			// if (state.language !== action.payload)
			return { ...initialState, language: action.payload }
		case LANGUAGE_LOADED:
			return {
				...action.payload,
				// language: action.payload.language,
				loading: false
			}
		default:
			return state
	}
}

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
// import { unescape as _unescape } from 'lodash-es'
// import _ from 'lodash'

import Settings from '../Settings'
import parseNumericHtmlEnteties from '../utils/parseNumericHtmlEnteties'

import BlocksRenderer from './BlocksRenderer'
import Footer from './Footer'

const SinglePageRenderer = ({
  page,
  loading,
  paddingTop,
  pageId,
  title,
  pages,
}) => {
  return (
    <div className="page_content">
      <Helmet>
        <title>
          {(!loading &&
            `${Settings.SiteName} - ${
              ('title' in page &&
                parseNumericHtmlEnteties(page.title.rendered)) ||
              'No Title'
            }`) ||
            'loading...'}
          {}
        </title>
        <meta
          name="description"
          content={
            page?.acf?.seo_description ||
            'Willkommen bei der Praxis Zeller König! Wir bieten Einzel-, Paar- und Familientherapie für Erwachsene jeden Alters an.'
          }
        />
      </Helmet>

      <BlocksRenderer page={page} loading={loading} />
      <Footer pages={pages} />
    </div>
  )
}

SinglePageRenderer.defaultProps = {
  page: { acf: {} },
  paddingTop: 140,
  title: 'SN',
}

SinglePageRenderer.propTypes = {
  page: PropTypes.shape({ acf: PropTypes.object }), // eslint-disable-line
  loading: PropTypes.bool.isRequired,
  paddingTop: PropTypes.number,
  pageId: PropTypes.string.isRequired,
  title: PropTypes.string,
}

const mapStateToProps = (state) => ({
  loading: state.pages.loading,
})

export default connect(mapStateToProps)(SinglePageRenderer)

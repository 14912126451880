import {
	SHOW_MESSAGE,
	SET_LANGUAGE,
	HIDE_MESSAGE,
	SHOW_MENU,
	HIDE_MENU
} from '../actions/types'

const initialState = {
	overlayVisible: false,
	messageVisible: false,
	messageText: '',
	messageType: '',
	menuVisible: false,
	language: 'en'
}

export default function (state = initialState, action) {
	switch (action.type) {
		case SHOW_MESSAGE:
			return {
				...state,
				messageVisible: true,
				messageText: action.payload.text,
				messageType: action.payload.type,
				messageDuration: action.payload.duration
			}
		case HIDE_MESSAGE:
			return {
				...state,
				messageVisible: false
			}
		case SET_LANGUAGE:
			return { ...state, language: action.payload }
		case SHOW_MENU:
			return { ...state, menuVisible: true }
		case HIDE_MENU:
			return { ...state, menuVisible: false }

		default:
			return state
	}
}

import { SHOW_MESSAGE, HIDE_MESSAGE, SHOW_MENU, HIDE_MENU } from './types'

export const hideMessage = () => dispatch => {
	dispatch({
		type: HIDE_MESSAGE
	})
}

export const showMessage = (text, type, duration = 3000) => dispatch => {
	dispatch({
		type: SHOW_MESSAGE,
		payload: { text, type, duration }
	})
}

export const showMenu = () => dispatch => {
	dispatch({
		type: SHOW_MENU
	})
}

export const hideMenu = () => dispatch => {
	dispatch({
		type: HIDE_MENU
	})
}

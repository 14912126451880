const Settings = {
  basename: '/',
  supportedLanguages: ['de'],
  SiteName: 'Praxis Zeller König',
  footerName: 'Praxis für Psychotherapie Zeller-König',
  defaultLanguage: 'de',
  placeholder: '',
  // apiBase: 'https://one-squared.com/sn/wp_server/0.3.0/wp-json',
  apiBase: 'https://praxis-zeller-koenig.de/backend/wp-json',
  cachedApiBase: 'https://cache.one-squared.com/',
  apiDomain: 'praxis-zeller-koenig',
  apiVersion: '&v=1',
  useCache: false,
  GoogleAnalyticsId: 'UA-140713645-1',
  translationLog:
    // 'https://api.one-squared.com/sn/translations/public/_/items/sn_translations_log'
    '',
  menu: [
    {
      title: 'Über Uns',
      url: '/ueber-uns',
    },
    {
      title: 'Therapeutisches Angebot',
      url: '/therapeutisches-angebot/',
    },
    {
      title: 'Praxis & Kontakt',
      url: '/praxis-und-kontakt/',
    },
  ],
  footerMenu: [
    {
      title: 'Über Uns',
      url: '/ueber-uns',
      subpages: [
        { title: 'Philosophie', url: '/ueber-uns#philosophie' },
        {
          title: 'Barbara Zeller-König',
          url: '/ueber-uns#barbara-zeller-koenig',
        },
        {
          title: 'Manfred König',
          url: '/ueber-uns#manfred-koenig',
        },
      ],
    },
    {
      title: 'Therapeutisches Angebot',
      url: '/therapeutisches-angebot/',
      subpages: [
        {
          title: 'Krisenintervention',
          url: '/therapeutisches-angebot#krisenintervention',
        },
        {
          title: 'Ambulante Einzeltherapie',
          url: '/therapeutisches-angebot#ambulante-einzeltherapie',
        },
        {
          title: 'Paar- und Familientherapie',
          url: '/therapeutisches-angebot#paar-und-familientherapie',
        },
        {
          title: 'Schematherapie',
          url: '/therapeutisches-angebot#schematherapie',
        },
        {
          title: 'Schematherapeutische Supervision',
          url: '/therapeutisches-angebot#schematherapeutische-supervision',
        },
      ],
    },
    {
      title: 'Praxis & Kontakt',
      url: '/praxis-und-kontakt/',
      subpages: [
        {
          title: 'Kontaktaufnahme und Procedere',
          url: '/praxis-und-kontakt#kontaktaufnahme',
        },
        {
          title: 'Anfahrt',
          url: '/praxis-und-kontakt#anfahrt',
        },
        {
          title: 'Datenschutz',
          url: '/impressum-und-datenschutz#datenschutz',
        },
        {
          title: 'Impressum',
          url: '/impressum-und-datenschutz/',
        },
      ],
    },
  ],
  mobileMenu: [
    {
      title: 'Über Uns',
      url: '/ueber-uns',
      subpages: [
        { title: 'Philosophie', url: '/ueber-uns#philosophie' },
        {
          title: 'Barbara Zeller-König',
          url: '/ueber-uns#barbara-zeller-koenig',
        },
        {
          title: 'Manfred König',
          url: '/ueber-uns#manfred-koenig',
        },
      ],
    },
    {
      title: 'Therapeutisches Angebot',
      url: '/therapeutisches-angebot/',
      subpages: [
        {
          title: 'Krisenintervention',
          url: '/therapeutisches-angebot#krisenintervention',
        },
        {
          title: 'Ambulante Einzeltherapie',
          url: '/therapeutisches-angebot#ambulante-einzeltherapie',
        },
        {
          title: 'Paar- und Familientherapie',
          url: '/therapeutisches-angebot#paar-und-familientherapie',
        },
        {
          title: 'Schematherapie',
          url: '/therapeutisches-angebot#schematherapie',
        },
        {
          title: 'Schematherapeutische Supervision',
          url: '/therapeutisches-angebot#schematherapeutische-supervision',
        },
      ],
    },
    {
      title: 'Praxis & Kontakt',
      url: '/praxis-und-kontakt/',
      subpages: [
        {
          title: 'Kontaktaufnahme und Procedere',
          url: '/praxis-und-kontakt#kontaktaufnahme',
        },
        {
          title: 'Anfahrt',
          url: '/praxis-und-kontakt#anfahrt',
        },
        {
          title: 'Datenschutz',
          url: '/impressum-und-datenschutz#datenschutz',
        },
        {
          title: 'Impressum',
          url: '/impressum-und-datenschutz/',
        },
      ],
    },
  ],
}

export default Settings

import { store } from '../store/store'
import { LOAD_PAGES, LANGUAGE_LOADED, SET_LANGUAGE } from './types'
import { api } from '../api'
import Settings from '../Settings'

export const pagesLoaded = pages => ({ type: LOAD_PAGES, payload: pages })
export const langLoaded = pages => ({ type: LANGUAGE_LOADED, payload: pages })

function handleErrors(responses) {
    // console.log(response)

    responses.map(response => {
        if (response.status !== 200) {
            throw Error(JSON.stringify(response))
        } else return response
    })
    return responses
}

export const loadPages = () => dispatch => {
    // console.log(Settings.supportedLanguages)
    let promises = []
    Settings.supportedLanguages.map(lang =>
        promises.push(
            api.pages.getPagesLang(lang, Settings.supportedLanguages.length > 1)
        )
    )
    Promise.all(promises)
        .then(res => handleErrors(res))
        .then(res => {
            let allLanguagesPages = {}
            res.map((singleRes, x) => {
                allLanguagesPages[Settings.supportedLanguages[x]] =
                    singleRes.data
                return null
            })
            console.log(allLanguagesPages)
            dispatch(langLoaded({ data: { ...allLanguagesPages } }))
        })
}

export const loadLanguage = (language, pageJustLoaded = false) => dispatch => {
    if (store.getState().ui.language !== language || pageJustLoaded) {
        let languageToLoad = language
        if (pageJustLoaded) {
            languageToLoad = store.getState().ui.language
                ? store.getState().ui.language
                : 'en'
        }
        dispatch({ type: SET_LANGUAGE, payload: languageToLoad })
        api.pages
            .getPagesLang(languageToLoad)
            .then(res => handleErrors(res))
            .then(res => res.data)
            .then(res => {
                return res.reduce((obj, item) => {
                    obj[item.slug] = item // eslint-disable-line
                    return obj
                }, {})
            })
            .then(pages =>
                dispatch(
                    langLoaded({ data: { ...pages }, language: languageToLoad })
                )
            )
            .catch(err => {
                console.log(err)
            })
    }
}

export const test = 'test'

import React from 'react'
import { Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Helmet } from 'react-helmet'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from './store/store'

import AppWrapper from './AppWrapper'
import LandingPage from './components/pages/LandingPage'
import LoadingScreen from './components/pages/LoadingScreen'
import MainMenu from './components/MainMenu'
import MobileMenu from './components/MobileMenu'
import Message from './components/Message'
import PageLoad from './components/PageLoad'
import GeneratedRoutes from './components/GeneratedRoutes'

import './css/_index.scss'
// import 'semantic-ui-css/semantic.min.css'

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
})

// const persistState = false

const App = () => (
  <Provider store={store}>
    <PageLoad>
      <PersistGate loading={null} persistor={persistor}>
        <AppWrapper>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Praxis für Psychotherapie Zeller-König</title>
          </Helmet>
          <div className="app_content">
            {null && <Route path="/" exact component={LandingPage} />}
            <GeneratedRoutes />
            <MainMenu />
            <MobileMenu />
          </div>
          <Message />
          <LoadingScreen />
        </AppWrapper>
      </PersistGate>
    </PageLoad>
  </Provider>
)

export default App

import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as IconForward } from '../../img/icon_forward.svg'
import { ReactComponent as IconForwardActive } from '../../img/icon_forward_active.svg'
import { ReactComponent as IconBack } from '../../img/icon_back.svg'
import { ReactComponent as IconBackActive } from '../../img/icon_back_active.svg'

const basePaddingBottom = 60

class Slider extends React.Component {
	constructor(props) {
		super(props)
		this.state = { currentSlide: 0 }
	}

	nextSlide = () => {
		const {
			props: { apiElement },
			state: { currentSlide }
		} = this

		const nrSlides = apiElement.slider
			? Object.values(apiElement.slider).length
			: null
		if (currentSlide + 1 < nrSlides) {
			this.setState({ currentSlide: currentSlide + 1 })
		}
	}

	lastSlide = () => {
		const {
			props: { apiElement },
			state: { currentSlide }
		} = this

		if (currentSlide > 0) {
			this.setState({ currentSlide: currentSlide - 1 })
		}
	}

	render() {
		const {
			props: { apiElement },
			state: { currentSlide }
		} = this
		// console.log(apiElement)

		const nrSlides = apiElement.slider
			? Object.values(apiElement.slider).length
			: null
		console.log(`nrSlides: ${nrSlides}, currentSlide: ${currentSlide}`)

		if (!nrSlides) return <div className="slider">No Slides Found</div>
		return (
			<div className="slider" id={apiElement.anchor_name}>
				{apiElement.slider &&
					Object.values(apiElement.slider).length > 0 && (
						<div
							className="slides_holder"
							style={{
								width: `${
									Object.values(apiElement.slider).length *
									100
								}%`,
								transform: `translate3d(-${
									currentSlide * (100 / nrSlides)
								}%, 0, 0)`
							}}
						>
							{Object.values(apiElement.slider).map(entry => (
								<div
									className="slide"
									style={{
										backgroundImage: `url(${entry.image.sizes.large})`,
										backgroundPosition: 'center',
										width: `${100 / nrSlides}%`,
										paddingBottom: `${
											basePaddingBottom / nrSlides
										}%`
									}}
								>
									{}
								</div>
							))}
							<div className="clearfix" />
						</div>
					)}
				<div className="nav_button_holder left">
					{currentSlide !== 0 && (
						<button
							className="nav_button left"
							onClick={this.lastSlide}
							type="button"
						>
							<div className="icon icon_back">
								<IconBack />
							</div>
							<div className="icon icon_back active">
								<IconBackActive />
							</div>
						</button>
					)}
				</div>

				<div className="nav_button_holder right">
					{currentSlide + 1 !== nrSlides && (
						<button
							className="nav_button right"
							onClick={this.nextSlide}
							type="button"
						>
							<div className="icon icon_forward">
								<IconForward />
							</div>
							<div className="icon icon_forward active">
								<IconForwardActive />
							</div>
						</button>
					)}
				</div>
			</div>
		)
	}
}
Slider.propTypes = {}

export default Slider

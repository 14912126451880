import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const HeaderImage = ({ apiElement }) => {
	console.log(apiElement.text)
	return (
		<>
			<Link
				to={apiElement.link}
				className={`page_link ${
					apiElement.layout ? 'image_left' : 'image_right'
				}`}
			>
				<div className="image_holder">
					<div
						className="image"
						style={{
							backgroundImage: `url('${apiElement.image.sizes.large}')`
						}}
					/>
					<div className="overlay" />
					<h2 className="hidden_on_tablet hidden_on_desktop">
						{apiElement.title}
					</h2>
				</div>
				<div className="info">
					<h2 className="hidden_on_mobile">{apiElement.title}</h2>
					<p>{apiElement.text}</p>
					<button type="button">{apiElement.button_text}</button>
				</div>
				<div className="clearfix" />
			</Link>
			{null && <p>{JSON.stringify(apiElement)}</p>}
		</>
	)
}
HeaderImage.propTypes = {}

export default HeaderImage

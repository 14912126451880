import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import SinglePageRenderer from './SinglePageRenderer'

const GeneratedRoutes = ({ pages }) => {
    console.log(pages)
    if (!pages.loading) {
        return (
            <React.Fragment>
				{pages.data.de.map(page => (
					<Route
						path={page.acf.url}
						key={page.acf.url}
						exact
						component={() => <SinglePageRenderer pages={pages} page={page} pageId={`page_${page.id}`} />}
					/>
				))}
			</React.Fragment>
        )
    }
    return null
}

const mapStateToProps = state => ({
    pages: state.pages
})

export default connect(mapStateToProps)(GeneratedRoutes)
import React from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import { BrowserView, isBrowser } from 'react-device-detect'

import { ReactComponent as MarkerIcon } from '../../img/icon_marker.svg'

const Marker = ({ apiElement }) => (
	<div className="lab_marker">
		<div className="icon">
			<MarkerIcon />
		</div>
		<a className="text" href={apiElement.link}>
			{apiElement.link_text}
		</a>
	</div>
)
const TestMarker = () => <div className="test_marker"></div>

const mapStyles = [
	{
		featureType: 'administrative',
		elementType: 'geometry',
		stylers: [
			{
				color: '#a7a7a7'
			}
		]
	},
	{
		featureType: 'administrative',
		elementType: 'labels.text.fill',
		stylers: [
			{
				visibility: 'on'
			},
			{
				color: '#ff7e5d'
			}
		]
	},
	{
		featureType: 'landscape',
		elementType: 'geometry.fill',
		stylers: [
			{
				visibility: 'on'
			},
			{
				color: '#efefef'
			}
		]
	},
	{
		featureType: 'poi',
		elementType: 'geometry.fill',
		stylers: [
			{
				visibility: 'on'
			},
			{
				color: '#dadada'
			}
		]
	},
	{
		featureType: 'poi',
		elementType: 'labels',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'poi',
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'road',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#696969'
			}
		]
	},
	{
		featureType: 'road',
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#ffffff'
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.stroke',
		stylers: [
			{
				visibility: 'on'
			},
			{
				color: '#b3b3b3'
			}
		]
	},
	{
		featureType: 'road.arterial',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#ffffff'
			}
		]
	},
	{
		featureType: 'road.arterial',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#d6d6d6'
			}
		]
	},
	{
		featureType: 'road.local',
		elementType: 'geometry.fill',
		stylers: [
			{
				visibility: 'on'
			},
			{
				color: '#ffffff'
			},
			{
				weight: 1.8
			}
		]
	},
	{
		featureType: 'road.local',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#d7d7d7'
			}
		]
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [
			{
				color: '#ff0000'
			},
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'water',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#d3d3d3'
			}
		]
	}
]

function createMapOptions(maps) {
	return {
		zoomControlOptions: {
			position: maps.ControlPosition.RIGHT_BOTTOM,
			style: maps.ZoomControlStyle.SMALL
		},
		mapTypeControlOptions: {
			position: maps.ControlPosition.TOP_RIGHT
		},
		mapTypeControl: false,
		fullscreenControl: false,
		scrollwheel: false,
		styles: mapStyles
	}
}
function createMapMobileOptions(maps) {
	return {
		zoomControlOptions: {
			position: maps.ControlPosition.RIGHT_BOTTOM,
			style: maps.ZoomControlStyle.SMALL
		},
		mapTypeControlOptions: {
			position: maps.ControlPosition.TOP_RIGHT
		},
		mapTypeControl: false,
		fullscreenControl: false,
		scrollwheel: false,
		styles: mapStyles,
		gestureHandling: 'greedy'
	}
}
class ContactMap extends React.Component {
	state = { error: false }
	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true })
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, info)
	}

	render = () => {
		const { apiElement } = this.props
		const { hasError } = this.state

		if (hasError)
			return (
				<div className="contact_map">
					<div className="content_wrapper">error</div>
				</div>
			)
		console.log(apiElement)
		return (
			<>
				<div className="contact_map">
					<div className="content_wrapper">
						{isBrowser && (
							<GoogleMapReact
								className="desktop_only"
								bootstrapURLKeys={{
									key:
										'AIzaSyAExyaWUivR9ppIc1yWmjvhetsITKuxzZE'
								}}
								defaultCenter={{
									lat: parseFloat(apiElement.latitude_map),
									lng: parseFloat(apiElement.longitude_map)
								}}
								defaultZoom={parseFloat(apiElement.map_zoom)}
								options={createMapOptions}
							>
								<Marker
									lat={parseFloat(apiElement.latitude_marker)}
									lng={parseFloat(
										apiElement.longitude_marker
									)}
									apiElement={apiElement}
								/>
							</GoogleMapReact>
						)}
						{!isBrowser && (
							<GoogleMapReact
								className="mobile_only"
								bootstrapURLKeys={{
									key:
										'AIzaSyAExyaWUivR9ppIc1yWmjvhetsITKuxzZE'
								}}
								defaultCenter={{
									lat: parseFloat(
										apiElement.latitude_map_mobile
									),
									lng: parseFloat(
										apiElement.longitude_map_mobile
									)
								}}
								defaultZoom={parseFloat(
									apiElement.map_zoom_mobile
								)}
								options={createMapMobileOptions}
							>
								<Marker
									lat={parseFloat(apiElement.latitude_marker)}
									lng={parseFloat(
										apiElement.longitude_marker
									)}
									apiElement={apiElement}
								/>
							</GoogleMapReact>
						)}
					</div>
				</div>

				<div
					className="address_holder phone_only"
					dangerouslySetInnerHTML={{
						__html: apiElement?.address || null
					}}
				></div>
			</>
		)
	}
}
ContactMap.propTypes = {
	apiElement: PropTypes.object.isRequired // eslint-disable-line
}

export default ContactMap

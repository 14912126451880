import React from 'react'

class LoadingScreen extends React.Component {
	state = { nrDots: 1 }
	componentDidMount = () => {
		const intervalMs = 600
		this.timer = window.setInterval(this.tick, intervalMs)
	}
	tick = () => {
		const maxDots = 3
		this.setState(oldState => ({
			nrDots: oldState.nrDots < maxDots ? oldState.nrDots + 1 : 0
		}))
	}
	render() {
		// return null
		const { nrDots } = this.state

		return (
			<div className="loading_screen">
				<span className="text">
					Wird geladen
					{[...Array(nrDots)].map(() => (
						<span>.</span>
					))}
				</span>
			</div>
		)
	}
}

export default LoadingScreen

import axios from 'axios'

import Settings from './Settings'

const { apiBase, cachedApiBase, useCache, apiDomain, apiVersion } = Settings

export const api = {
    pages: {
        getPages: () =>
            axios.get(`${apiBase}/wp/v2/pages?per_page=100`).then(res => res),
        getPagesLang: (language, multilanguage) => {
            // Very weird hack to avoid cors problem with lang=en
            // Could have to do with redirection maybe?
            // Bla
            let langString = ''
            if (multilanguage) {
                langString = `&lang=${language}`
            }
            console.log('Fetching Pages:')
            console.log(
                `${apiBase}/wp/v2/pages?per_page=100${langString}${apiVersion}`
            )
            return axios
                .get(
                    `${apiBase}/wp/v2/pages?per_page=100${langString}${apiVersion}`,
                    {
                        maxRedirects: 5
                    }
                )
                .then(res => res)
        }
    },

    options: {
        getOptionsLang: language => {
            const url = useCache
                ? `${cachedApiBase}?domain=${apiDomain}&path=options_${language}${apiVersion}`
                : `${apiBase}/acf/v3/options/options?lang=${language}${apiVersion}`

            return axios.get(url).then(res => res)
            // return (
            //     axios
            //         // .get(`${apiBase}/acf/v3/options/options?lang=${language}`)
            //         .get(
            //             `${cachedApiBase}?domain=rothcoaching&path=options_${language}`
            //         )
            //         .then(res => res)
            // )
        }
    },

    browserLanguages: `${apiBase}/browser_lang/browser_lang`
}

export default api

import React from 'react'
import PropTypes from 'prop-types'

const HeaderImage = ({ apiElement }) => {
	console.log(apiElement.text)
	return (
		<div
			className="header_image"
			style={{
				backgroundImage: `url(${apiElement.image.sizes.large})`,
				backgroundPosition: apiElement.image_position
			}}
		>
			{apiElement.text.length > 0 ? <h1>{apiElement.text}</h1> : null}
		</div>
	)
}
HeaderImage.propTypes = {
	imageUrl: PropTypes.string.isRequired
}

export default HeaderImage

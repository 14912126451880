import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import $ from 'jquery'

import { loadPages } from './actions/pagesActions'
import { loadOptions } from './actions/optionsActions'

import { loadBrowserLanguages } from './actions/browserLanguagesActions'

// import setAuthorizationHeader from './utils/setAuthorizationHeader'

// import { SET_LOADING } from './actions/types'
// import { store } from './store/store'

class AppWrapper extends Component {
	// constructor(props) {
	// 	super(props)
	// }

	componentDidMount() {
		/* eslint-disable */
		// this.props.loadBrowserLanguages()
		// if (this.props.pagesLoading) {
		// }

		// const alwaysRefresh = true
		// if (alwaysRefresh) {
		// 	store.dispatch({ type: SET_LOADING })
		// }

		const { redux } = this.props
		if (redux.pages.loading) {
			this.props.loadPages(null, true)
		}

		loadOptions()
		/* eslint-enable */
		// console.log(redusx)
	}

	componentDidUpdate(prevProps) {
		// const { overlayVisible, messageVisible } = this.props
		// if (
		// 	prevProps.overlayVisible !== overlayVisible ||
		// 	prevProps.messageVisible !== messageVisible
		// ) {
		// 	if (overlayVisible || messageVisible)
		// 		$('html').css('overflow', 'hidden')
		// 	else $('html').removeAttr('style')
		// }
	}

	render() {
		const {
			children,
			pagesLoading,
			overlayVisible,
			messageVisible
		} = this.props
		return (
			<div
				className={
					pagesLoading || overlayVisible || messageVisible
						? 'app_wrapper loading'
						: 'app_wrapper'
				}
			>
				{children}
			</div>
		)
	}
}

const mapStatetoProps = state => ({
	pagesLoading: state.pages.loading,
	overlayVisible: state.ui.overlayVisible,
	messageVisible: state.ui.messageVisible,
	language: state.ui.language,
	redux: state
})

AppWrapper.defaultProps = {
	children: ''
}

AppWrapper.propTypes = {
	children: PropTypes.array, // eslint-disable-line
	pagesLoading: PropTypes.bool.isRequired,
	overlayVisible: PropTypes.bool.isRequired,
	messageVisible: PropTypes.bool.isRequired
}

export default connect(mapStatetoProps, {
	loadPages,
	loadOptions,
	loadBrowserLanguages
})(AppWrapper)
